import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { FormControl, InputLabel, Select, MenuItem, Box } from "@mui/material";
import { Send } from "@material-ui/icons";
import { ColorPicker } from "./ColorPicker";
import { SizeQuantityTable } from "./SizeQuantityTable";
import { CategorySelect } from "./CategorySelect";
import { categories, sousCategories, marks, seasons } from "./constants";
// import './newProduct1.css';
import "../../pages/prodctList/newProduct/newProduct1.css";
import { PriceInformation } from "./PriceInformation";
import { BasicInformation } from "./BasicInformation";
import { addProduct } from "../../redux/apiCalls";
import DropZone from "../upload/DropZone";
import { LoadingButton } from "@mui/lab";

export function ProductForm() {
  const [inputs, setInputs] = useState({ gender: "male", img: [] });
  const [inputList, setInputList] = useState([{ size: "", quantity: "" }]);
  const [isLoading, setIsLoading] = useState(false);
  const [colorsFiltered, setColorsFiltered] = useState([]);
  const [value, setValue] = useState("men");
  const [cat, setCat] = useState([]);
  const [souscat, setSousCat] = useState([]);
  const [mark, setMark] = useState([]);
  const [season, setSeason] = useState([]);
  const [checked, setChecked] = useState(false);
  const [sold, setSold] = useState({});
  const [images, setImages] = useState([]);
  const [files, setFiles] = useState([]);
  const [mainImageIndex, setMainImageIndex] = useState(0);
  const [isImagesUploading, setIsImagesUploading] = useState(false);

  const dispatch = useDispatch();
  const handleColorSelect = (color) => {
    setColorsFiltered((prev) =>
      prev.includes(color) ? prev.filter((c) => c !== color) : [...prev, color]
    );
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const handleRemoveSize = (index) => {
    setInputList((prev) => prev.filter((_, i) => i !== index));
  };

  const handleAddSize = () => {
    setInputList((prev) => [...prev, { size: "", quantity: "" }]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (images.length) {
      const imagesSorted = [...images]
        .sort((a, b) => (a === images[mainImageIndex] ? -1 : 1))
        .map((e) => e.url);

      try {
        setIsLoading(true);
        await addProduct(
          {
            ...inputs,
            img: imagesSorted,
            cat,
            souscat,
            mark,
            attr: inputList,
            sold,
            gender: value,
            color: colorsFiltered,
            season,
          },
          dispatch
        );

        // Reset form
        setFiles([]);
        setInputs({ gender: "male", img: [] });
        setCat([]);
        setSousCat([]);
        setMark([]);
        setInputList([{ size: "", quantity: "" }]);
        setSold({});
        setValue("men");
        setColorsFiltered([]);
        setSeason([]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <form className="addProductForm" onSubmit={handleSubmit}>
      <div className="newProduct">
        <div className="from-title">Product information:</div>
        <hr style={{ marginBottom: "20px" }} />
        <div className="wrapper">
          {/* Left Column */}
          <div className="left">
            <div className="form-container">
              <div className="labels-inputs">
                {/* Basic Information */}
                <BasicInformation inputs={inputs} setInputs={setInputs} />

                {/* Color Picker */}
                <ColorPicker
                  selectedColors={colorsFiltered}
                  onColorSelect={handleColorSelect}
                />

                {/* Size/Quantity Table */}
                <SizeQuantityTable
                  inputList={inputList}
                  onInputChange={handleInputChange}
                  onRemove={handleRemoveSize}
                  onAdd={handleAddSize}
                />
              </div>
            </div>
          </div>

          {/* Right Column */}
          <div className="right">
            {/* Price Information */}
            <PriceInformation
              inputs={inputs}
              setInputs={setInputs}
              sold={sold}
              setSold={setSold}
              checked={checked}
              setChecked={setChecked}
            />

            {/* Category Selects */}
            <CategorySelect
              label="Categories"
              options={categories}
              value={cat}
              onChange={(e) => setCat(e.target.value)}
            />
            <CategorySelect
              label="Sous Categories"
              options={sousCategories}
              value={souscat}
              onChange={(e) => setSousCat(e.target.value)}
            />
            <CategorySelect
              label="Mark"
              options={marks}
              value={mark}
              onChange={(e) => setMark(e.target.value)}
            />
            <CategorySelect
              label="Season"
              options={seasons}
              value={season}
              onChange={(e) => setSeason(e.target.value)}
            />

            {/* Gender Select */}
            <Box sx={{ maxWidth: 120 }} className="addProductItem">
              <FormControl fullWidth>
                <InputLabel>Gender*</InputLabel>
                <Select
                  value={value}
                  label="Gender"
                  onChange={(e) => {
                    setValue(e.target.value);
                    setInputs((prev) => ({ ...prev, gender: e.target.value }));
                  }}
                >
                  <MenuItem value="men">Men</MenuItem>
                  <MenuItem value="women">Women</MenuItem>
                  <MenuItem value="unisex">Unisex</MenuItem>
                  <MenuItem value="kids">Kids</MenuItem>
                </Select>
              </FormControl>
            </Box>

            {/* Image Upload */}
            <div className="form-container" style={{ marginBottom: 50 }}>
              <DropZone
                onAllUploaded={setImages}
                setIsUploading={setIsImagesUploading}
                isUploading={isImagesUploading}
                setMainImageIndex={setMainImageIndex}
                mainImageIndex={mainImageIndex}
                files={files}
                setFiles={setFiles}
              />
            </div>

            {/* Submit Button */}
            <LoadingButton
              size="large"
              endIcon={<Send />}
              loading={isLoading}
              loadingPosition="end"
              variant="contained"
              type="submit"
              disabled={isImagesUploading || isLoading}
            >
              Send
            </LoadingButton>
          </div>
        </div>
      </div>
    </form>
  );
}
