import axios from "axios"

// const url = "http://localhost:5000"
const url = "https://api.noahstoredz.com"
export const BASE_URL = `${url}/api/`

// const TOKEN = "";
const isUser = localStorage.getItem('currentUser') !== null
const TOKEN = isUser ? JSON.parse(localStorage.getItem('currentUser')).accessToken : "" 
// const TOKEN = ""


export const publicRequest = axios.create({
  baseURL: BASE_URL,
});

export const userRequest = axios.create({
  baseURL: BASE_URL,
  headers: { token: `Bearer ${TOKEN}` },
});

export const fetcher = url => userRequest.get(url).then(res => res.data)
