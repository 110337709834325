import React, { useCallback, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
  debounce,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { Add, Remove, Delete } from "@material-ui/icons";
import { BASE_URL, publicRequest, userRequest } from "../../requestMethods";
import toast from "react-hot-toast";
import { Flex } from "@mantine/core";

export const CheckoutDashboard = () => {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [clientEmail, setClientEmail] = useState("");
  const [clientPhone, setClientPhone] = useState("");
  const [clientPaymentMethod, setClientPaymentMethod] = useState("cash");

  const handleQuantityChange = (variantId, delta) => {
    setSelectedProducts(
      selectedProducts.map((product) => {
        return product.variantId === variantId
          ? {
              ...product,
              quantity: Math.max(1, product.quantity + delta),
            }
          : product;
      })
    );
  };

  const handleDiscountChange = (variantId, discount) => {
    setSelectedProducts(
      selectedProducts.map((product) =>
        product.variantId === variantId
          ? { ...product, discount: Math.max(0, discount) }
          : product
      )
    );
  };

  const handleRemoveProduct = (variantId) => {
    setSelectedProducts(
      selectedProducts.filter((product) => product.variantId !== variantId)
    );
  };

  const handleCancel = () => {
    resetCheckout();
  };

  const saleSubmit = async () => {
    try {
      const response = userRequest.post("/sales", {
        products: selectedProducts.map((product) => {
          return {
            productRef: product.ref,
            name: product.title,
            unitPrice: product.price,
            quantity: product.quantity,
            discount: product.discount,
            totalPrice: product.price,
            selectedAttribute: {
              attributeId: product.variantId,
              size: product.size,
              color: product.color.join(","),
            },
          };
        }),
        email: clientEmail,
        phone: clientPhone,
        totalPurchase: calculateTotal(),
      });
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const handleSubmitClick = () => {
    toast.promise(saleSubmit(), {
      loading: "Saving...",
      success: () => {
        resetCheckout();
        return <b>saved!</b>;
      },
      error: (error) => {
        const message = error?.response?.data?.message;
        return <b>{message}</b>;
      },
    });
    console.log("Purchase submitted:", selectedProducts);
  };

  const calculateTotal = () => {
    return selectedProducts
      .reduce((sum, product) => {
        return sum + (product.price * product.quantity - product.discount);
      }, 0)
      .toFixed(2);
  };

  const [searchedProducts, setSearchedProducts] = useState([]);
  const [searchedValue, setSearchedValue] = useState("");
  const [isProductFetching, setIsProductFetching] = useState(false);

  const mapProductsToVariants = (products) => {
    const variants = [];
    products.forEach((product) => {
      return product.attr.forEach((variant) => {
        const newVariant = {
          ...product,
          ...variant,
          productId: product._id,
          availableQuality: variant.quantity,
          variantId: variant._id,
          quantity: 1,
        };
        variants.push(newVariant);
      });
    });
    return variants;
  };

  const getSearchedProducts = async (value) => {
    setIsProductFetching(true);
    try {
      const res = await publicRequest.get(`/products/search/${value}`);
      setSearchedProducts(mapProductsToVariants(res?.data ?? []));
    } catch (err) {
      setIsProductFetching(false);
    } finally {
      setIsProductFetching(false);
    }
  };

  const handleSelectProduct = (product) => {
    if (!product) return;
    if (!selectedProducts.some((p) => p.variantId === product.variantId)) {
      setSelectedProducts([
        ...selectedProducts,
        { ...product, quantity: 1, discount: 0 },
      ]);
    }
    setSearchedValue("");
  };

  const resetCheckout = () => {
    setClientEmail("");
    setClientPhone("");
    setClientPaymentMethod("cash");
    setIsProductFetching(false);
    setSearchedProducts([]);
    setSelectedProducts([]);
    setSearchedValue("");
  };

  // Create a memoized debounced function using useCallback
  const debouncedGetProducts = useCallback(
    debounce((value) => {
      getSearchedProducts(value);
    }, 500),
    [] // Empty dependency array since we don't want to recreate this function
  );

  // Update the handle search change function
  const handleSearchChange = (event, value) => {
    setSearchedValue(value);
    debouncedGetProducts(value);
  };

  return (
    <Box sx={{ p: 4, flex: 4 }}>
      <Typography variant="h5" textAlign={"left"} mb={4} fontWeight={"bold"}>
        Checkout Page
      </Typography>
      <Flex gap={20} sx={{}} mb={40}>
        <TextField
          type="email"
          placeholder="client email"
          value={clientEmail}
          onChange={(e) => setClientEmail(e.target.value)}
        />
        <TextField
          placeholder="client phone"
          type="text"
          value={clientPhone}
          onChange={(e) => setClientPhone(e.target.value)}
        />
        <FormControl sx={{ width: 200 }}>
          <InputLabel id="payment method">payment method</InputLabel>
          <Select
            labelId="payment method"
            id="payment method"
            value={clientPaymentMethod}
            onChange={(e) => setClientPaymentMethod(e.target.value)}
          >
            <MenuItem value={"cash"}>Cash</MenuItem>
            <MenuItem value={"card"}>Card</MenuItem>
            <MenuItem value={"other"}>Other</MenuItem>
          </Select>
        </FormControl>
      </Flex>
      <Autocomplete
        options={searchedProducts}
        inputValue={searchedValue}
        onInputChange={handleSearchChange}
        getOptionLabel={(option) =>
          `${option?.title} ${option?.size ?? ""} ${option?.color ?? ""} (${
            option?.ref
          }) `
        }
        onChange={(event, newValue) => handleSelectProduct(newValue)}
        renderInput={(params) => (
          <TextField {...params} label="Select Product" variant="outlined" />
        )}
        loading={isProductFetching}
      />

      {selectedProducts.length > 0 && (
        <TableContainer component={Paper} sx={{ mt: 4 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>img</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Unit Price</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Total Price</TableCell>
                <TableCell>Discount</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedProducts.map((product) => (
                <TableRow key={product.variantId}>
                  <TableCell>
                    {" "}
                    <div className="productListItem">
                      <img
                        className="productListImg-sale-page"
                        src={BASE_URL + "media/image/" + product.img[0]}
                        alt=""
                      />
                    </div>
                  </TableCell>
                  <TableCell>{`${product?.title} ${product?.size ?? ""} ${
                    product?.color ?? ""
                  } (${product?.ref}) `}</TableCell>
                  <TableCell>{product.price.toFixed(2)}</TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <IconButton
                        onClick={() =>
                          handleQuantityChange(product.variantId, -1)
                        }
                      >
                        <Remove />
                      </IconButton>
                      {product.quantity}
                      <IconButton
                        onClick={() =>
                          handleQuantityChange(product.variantId, 1)
                        }
                      >
                        <Add />
                      </IconButton>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {(
                      product.price * product.quantity -
                      product.discount
                    ).toFixed(2)}
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={product.discount}
                      onChange={(e) =>
                        handleDiscountChange(
                          product.variantId,
                          parseFloat(e.target.value) || 0
                        )
                      }
                      inputProps={{ min: 0 }}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      color="error"
                      onClick={() => handleRemoveProduct(product.variantId)}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {selectedProducts.length > 0 && (
        <Typography
          variant="h6"
          fontWeight={400}
          sx={{
            mt: 2,
            textAlign: "right",
            bgcolor: "#0076d753",
            width: "fit-content",
            py: 2,
            px: 1,
            ml: "auto",
            borderRadius: "10px",
            textTransform: "uppercase",
          }}
        >
          Total Purchase: <b>{calculateTotal()}</b> DZD
        </Typography>
      )}

      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
        <Button variant="outlined" color="error" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          disabled={!selectedProducts.length}
          variant="contained"
          color="primary"
          onClick={handleSubmitClick}
        >
          Submit Purchase
        </Button>
      </Box>
    </Box>
  );
};
