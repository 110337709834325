import { createSlice } from "@reduxjs/toolkit";

const currentUser =
  localStorage.getItem("currentUser") === null
    ? null
    : JSON.parse(localStorage.getItem("currentUser"));

const userSlice = createSlice({
  name: "user",
  initialState: {
    currentUser: currentUser,
    isAuthenticated: false,
    isFetching: false,
    error: false,
  },
  reducers: {
    loginStart: (state) => {
      state.isFetching = true;
    },
    loginSuccess: (state, action) => {
      state.isFetching = false;
      state.currentUser = action.payload;
      state.loginFailure = false;
      state.isAuthenticated = true;
      window.location.reload();
    },
    loginFailure: (state) => {
      state.isFetching = false;
      state.loginFailure = true;
      state.isAuthenticated = false;
    },
    resetUser: (state) => {
      state.currentUser = null;
      state.isAuthenticated = false;
      localStorage.removeItem("currentUser");
      window.location.reload();
    },
  },
});

export const { loginStart, loginSuccess, loginFailure, resetUser } =
  userSlice.actions;
export default userSlice.reducer;
