import React from 'react';
import { InputLabel } from '@mui/material';

export const BasicInformation = ({ inputs, setInputs }) => {
  const handleChange = (e) => {
    setInputs(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  return (
    <>
      <div className="addProductItem">
        <InputLabel>Name: *</InputLabel>
        <input
          onChange={handleChange}
          name="title"
          type="text"
          placeholder="Nike air force 1"
          required
        />
      </div>
      <div className="addProductItem">
        <InputLabel>Ref: *</InputLabel>
        <input
          onChange={handleChange}
          name="ref"
          type="text"
          placeholder="XXX-xxx"
          required
        />
      </div>
      <div className="addProductItem">
        <InputLabel>Link To: *</InputLabel>
        <input
          onChange={handleChange}
          name="linkTo"
          type="text"
          placeholder="XXX-xxx"
          required
        />
      </div>
      <div className="addProductItem">
        <InputLabel>Description: *</InputLabel>
        <textarea
          onChange={handleChange}
          name="desc"
          placeholder="The Esqido Companion Eyelash Glue is the ..."
          required
        />
      </div>
    </>
  );
};