export const ITEM_HEIGHT = 48;
export const ITEM_PADDING_TOP = 8;

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const categories = ["shoes", "clothes", "accessories"];
export const sousCategories = [
  "pants", "outerwear", "polo", "tshirt", "sweatshirt",
  "pantacourt", "shoes", "sandals", "flip-flops", "cap",
  "backpack"
];

export const marks = [
  "nike", "blueva", "jordan", "adidas", "under-armour",
  "puma", "champion", "new balance", "umbro", "le coq sportif",
  "reebok", "sketchers", "converse", "vans", "caterpillar",
  "hush puppies", "sebago", "clarks", "crocs", "hummel",
  "geox", "harmont blaine", "havaianas", "asics", "pro touch"
];

export const seasons = ["all", "winter", "summer", "spring", "autumn"];

export const colors = [
  { id: "purple", color: "#8D429F", label: "Purple" },
  { id: "black", color: "#111", label: "Black" },
  { id: "red", color: "#E7352B", label: "Red" },
  { id: "orange", color: "#F36B26", label: "Orange" },
  { id: "blue", color: "#1790C8", label: "Blue" },
  { id: "white", color: "#fff", label: "White" },
  { id: "brown", color: "#825D41", label: "Brown" },
  { id: "green", color: "#7BBA3C", label: "Green" },
  { id: "mix", color: "", label: "Multi-Color" },
  { id: "grey", color: "#808080", label: "Grey" },
  { id: "pink", color: "#F0728F", label: "Pink" },
  { id: "yellow", color: "#ffee00", label: "Yellow" },
];