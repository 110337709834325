import { Sidebar } from "./components/sidebar/Sidebar";
import { Topbar } from "./components/topbar/Topbar";
import "./app.css";
import { Home } from "./pages/home/Home";
import Login from "./pages/login/Login";

import UserList from "./pages/userList/UserList";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import User from "./pages/userPage/User";
import NewUser from "./pages/newUser/NewUser";
import ProductList from "./pages/prodctList/ProductList";
import Product from "./pages/product/Product";
// import NewProduct from "./pages/prodctList/newProduct/NewProduct";
import CouponList from "./pages/couponList/CouponList";
import NewCoupon from "./pages/newCoupon/NewCoupon";
import Coupon from "./pages/coupon/Coupon";

import ScrollToTop from "./helpers/ScrollToTop";
import Delivery from "./pages/delivery/Delivery";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { resetUser } from "./redux/userRedux";
import OrderList from "./pages/orderList/OrderList";
import { ProductForm } from "./components/product-form/NewProduct";
import { CheckoutDashboard } from "./pages/checkout/CheckoutDashboard";
import { Toaster } from "react-hot-toast";
import SalesPage from "./pages/checkoutList/CheckoutList";
import { QueryClient, QueryClientProvider } from "react-query";
import { CheckoutEdit } from "./pages/checkoutEdit/CheckoutEdit";

const App = () => {
  // const admin = JSON.parse(localStorage.getItem('currentUser')) === null ? false : JSON.parse(localStorage.getItem('currentUser')).isAdmin
  // const admin = false;
  // const user = localStorage.getItem("currentUser") !== null;

  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.currentUser);
  const expiryDate = user?.expiredDate;

  useEffect(() => {
    const logout = () => {
      dispatch(resetUser());
      console.log("logout");
    };
    if (expiryDate && Date.now() >= expiryDate) {
      logout();
    }
    console.count("fist test");
    const interval = setInterval(() => {
      if (expiryDate && Date.now() >= expiryDate) {
        logout();
      }
    }, 120000); // 120000
    return () => clearInterval(interval);
  }, []);

  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Toaster />
        <ScrollToTop />

        <Switch>
          <Route path="/login">{user ? <Redirect to="/" /> : <Login />}</Route>
          {user ? (
            <div className="app">
              <Topbar />
              <div className="container">
                <Sidebar />
                <Route exact path="/">
                  <Home />
                </Route>
                <Route path="/users">
                  <UserList />
                </Route>
                <Route path="/user/:userId">
                  <User />
                </Route>
                <Route path="/newUser">
                  <NewUser />
                </Route>
                <Route path="/products">
                  <ProductList />
                </Route>
                <Route path="/product/:productId">
                  <Product />
                </Route>
                <Route path="/newproduct">
                  <ProductForm />
                </Route>
                <Route path="/coupons">
                  <CouponList />
                </Route>
                <Route path="/newcoupon">
                  <NewCoupon />
                </Route>
                <Route path="/coupon/:couponId">
                  <Coupon />
                </Route>
                <Route path="/delivery">
                  <Delivery />
                </Route>
                <Route path="/orders">
                  <OrderList />
                </Route>
                <Route path="/checkout">
                  <CheckoutDashboard />
                </Route>
                <Route path="/sales" exact>
                  <SalesPage />
                </Route>
                <Route path="/sales/:saleId">
                  <CheckoutEdit />
                </Route>
              </div>
            </div>
          ) : (
            <Redirect to="/login" />
          )}
        </Switch>
      </Router>
    </QueryClientProvider>
  );
};

export default App;
