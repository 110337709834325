import React from 'react';
import { InputLabel, Checkbox } from '@mui/material';

export const PriceInformation = ({ 
  inputs, 
  setInputs, 
  sold, 
  setSold, 
  checked, 
  setChecked 
}) => {
  const handleChange = (e) => {
    setInputs(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleSoldPriceChange = (e) => {
    setSold(prev => ({
      ...prev,
      soldPrice: e.target.value
    }));
  };

  const handleCheckChange = (e) => {
    setChecked(e.target.checked);
    setSold(prev => ({
      ...prev,
      inSold: e.target.checked
    }));
  };

  return (
    <>
      <div className="addProductItem">
        <InputLabel>Price: *</InputLabel>
        <input
          onChange={handleChange}
          name="price"
          type="number"
          placeholder=""
          required
          min="0"
        />
      </div>
      <div className="addProductItem">
        <InputLabel>Buying Price: *</InputLabel>
        <input
          onChange={handleChange}
          name="buyingPrice"
          type="number"
          placeholder=""
          required
          min="0"
        />
      </div>
      <div className="addProductItem">
        <InputLabel>Sold Price:</InputLabel>
        <div className="soldInput">
          <input
            className="input"
            onChange={handleSoldPriceChange}
            name="soldPrice"
            type="number"
            placeholder=""
            min="0"
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p>in sold?</p>
            <Checkbox
              checked={checked}
              onChange={handleCheckChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </div>
        </div>
      </div>
    </>
  );
};