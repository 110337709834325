import React from 'react';
import { colors } from './constants';
import { Color, ColorCircle, ColorName, GridCenter, Sizes } from './styles';
import { CheckMark, DarkCheckMark } from './icons';
import { InputLabel } from '@mui/material';

export const ColorPicker = ({ selectedColors, onColorSelect }) => {
  return (
    <GridCenter>
      <InputLabel>Color: *</InputLabel>
      <Sizes>
        {colors.map(({ id, color, label }) => (
          <Color key={id} onClick={() => onColorSelect(id)}>
            <ColorCircle color={color}>
              {selectedColors.includes(id) && (id === "white" ? <DarkCheckMark /> : <CheckMark />)}
            </ColorCircle>
            <ColorName>{label}</ColorName>
          </Color>
        ))}
      </Sizes>
    </GridCenter>
  );
};