import React, { useState } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TablePagination,
} from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import dayjs from "dayjs";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { userRequest } from "../../requestMethods";
import { Delete, Edit } from "@material-ui/icons";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const fetchSales = async (filters, page, limit) => {
  const { data } = await userRequest.get("/sales", {
    params: { ...filters, page, limit },
  });
  return data;
};

const deleteSale = async (saleId) => {
  const { data } = await userRequest.delete(`/sales/${saleId}`);
  return data;
};

const SalesPage = () => {
  const [filters, setFilters] = useState({
    startDate: "",
    endDate: "",
    paymentMethod: "",
    customer: "",
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [saleToDelete, setSaleToDelete] = useState(null);

  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery(
    ["sales", filters, page, rowsPerPage],
    () => fetchSales(filters, page + 1, rowsPerPage),
    {
      keepPreviousData: true,
    }
  );

  const mutation = useMutation(deleteSale, {
    onSuccess: () => {
      queryClient.invalidateQueries("sales");
    },
  });

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  const handleDeleteConfirm = (saleId) => {
    setSaleToDelete(saleId);
    setConfirmDelete(true);
  };

  const handleDelete = () => {
    if (saleToDelete) {
      mutation.mutate(saleToDelete);
      setConfirmDelete(false);
      setSaleToDelete(null);
    }
  };

  const handlePageChange = (_, newPage) => {
    console.log(newPage + 1);
    setPage(newPage);
    setFilters((prev) => ({
      ...prev,
      page: newPage + 1, // Ensure page is at least 1
    }));
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const totalQuantity = (sale) =>
    sale.products.reduce((total, product) => total + product.quantity, 0);

  const totalDiscount = (sale) =>
    sale.products.reduce((total, product) => total + product.discount, 0);

  // total amount with discount cuts
  const totalAmount = (sale) =>
    sale.products.reduce((total, product) => {
      return total + (product.unitPrice * product.quantity - product.discount);
    }, 0);

  return (
    <Box sx={{ padding: 3, flex: 4 }}>
      <Typography variant="h4" gutterBottom>
        Sales Listing
      </Typography>
      {/* Filters */}
      <Box
        sx={{
          display: "flex",
          gap: 2,
          flexWrap: "wrap",
          marginBottom: 2,
        }}
      >
        <TextField
          type="date"
          label="Start Date"
          value={filters.startDate}
          onChange={(e) => handleFilterChange("startDate", e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          type="date"
          label="End Date"
          value={filters.endDate}
          onChange={(e) => handleFilterChange("endDate", e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
        <FormControl sx={{ minWidth: 150 }}>
          <InputLabel>Payment Method</InputLabel>
          <Select
            value={filters.paymentMethod}
            onChange={(e) =>
              handleFilterChange("paymentMethod", e.target.value)
            }
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="cash">Cash</MenuItem>
            <MenuItem value="card">Card</MenuItem>
            <MenuItem value="other">Other</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Search by Customer"
          value={filters.customer}
          onChange={(e) => handleFilterChange("customer", e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => queryClient.invalidateQueries("sales")}
        >
          Apply Filters
        </Button>
      </Box>
      {/* Sales Chart */}
      <Typography variant="h5" gutterBottom>
        Sales Trends
      </Typography>
      <Box sx={{ height: 300, marginBottom: 3 }}>
        <ResponsiveContainer>
          <LineChart
            data={data?.sales
              ?.map((sale) => ({
                date: dayjs(sale.createdAt).format("MMM DD"),
                revenue: totalAmount(sale),
              }))
              .sort((a, b) => new Date(a.date) - new Date(b.date))}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Line type="monotone" dataKey="revenue" stroke="#8884d8" />
          </LineChart>
        </ResponsiveContainer>
      </Box>
      {/* Sales Table */}
      {!isLoading && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Number of Products</TableCell>
                <TableCell>Total Price</TableCell>
                <TableCell>Total Discount</TableCell>
                <TableCell>Payment Method</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.sales.map((sale) => (
                <TableRow key={sale._id}>
                  <TableCell>
                    {dayjs(sale.createdAt).format("MMM DD, YYYY HH:mm")}
                  </TableCell>
                  <TableCell>{totalQuantity(sale)}</TableCell>
                  <TableCell>DA {totalAmount(sale)}</TableCell>
                  <TableCell>DA {totalDiscount(sale) ?? 0}</TableCell>
                  <TableCell>{sale?.paymentMethod}</TableCell>
                  <TableCell>
                    <Button
                      color="secondary"
                      size="small"
                      onClick={() => handleDeleteConfirm(sale._id)}
                    >
                      <Delete />
                    </Button>
                    <Link to={`/sales/${sale._id}`}>
                      <Button color="primary" size="small">
                        <Edit />
                      </Button>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={data?.totalSales || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </TableContainer>
      )}
      {isLoading && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
      {/* Delete Confirmation Dialog */}
      <Dialog open={confirmDelete} onClose={() => setConfirmDelete(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this sale?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDelete(false)}>Cancel</Button>
          <Button onClick={handleDelete} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SalesPage;
