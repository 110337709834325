import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, Box, Chip, OutlinedInput } from '@mui/material';
import { MenuProps } from './constants';
import { useTheme } from '@mui/material/styles';

export const CategorySelect = ({ 
  label, 
  options, 
  value, 
  onChange, 
  width = 300 
}) => {
  const theme = useTheme();

  const getStyles = (name, selectedValues, theme) => ({
    fontWeight:
      selectedValues.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  });

  return (
    <FormControl sx={{ m: 1, width }}>
      <InputLabel id={`${label}-label`}>{label}</InputLabel>
      <Select
        labelId={`${label}-label`}
        multiple
        value={value}
        onChange={onChange}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {options.map((name) => (
          <MenuItem
            key={name}
            value={name}
            style={getStyles(name, value, theme)}
          >
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};