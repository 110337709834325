import styled from "styled-components";

export const ColorName = styled.p`
  padding-top: 2px;
  font-size: 14px;
`;

export const Color = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

export const ColorCircle = styled.div`
  width: 28px;
  height: 28px;
  text-align: center;
  border-radius: 14px;
  border: 1px solid #1111;
  box-sizing: border-box;
  padding: 5px;
  background-color: ${(props) => props.color};
`;

export const GridCenter = styled.div`
  width: 250px;
  display: grid;
  place-content: left;
`;

export const Sizes = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 7px;
  margin-bottom: 30px;
  margin-top: 20px;
  padding: 2px;
  border: 1px solid ${(props) => (props.clickable === "red" ? "#631212" : "transparent")};
`;