import React from 'react';
import { Button } from '@mui/material';

export const SizeQuantityTable = ({ inputList, onInputChange, onRemove, onAdd }) => {
  return (
    <div className="variants-container">
      <div className="variant">
        <table>
          <tbody>
            <tr>
              <th style={{ width: "120px" }}>Size</th>
              <th style={{ width: "200px" }}>Quantity</th>
              <th>Delete</th>
            </tr>
            {inputList.map((x, i) => (
              <tr key={i}>
                <th>
                  <input
                    name="size"
                    value={x.size}
                    onChange={(e) => onInputChange(e, i)}
                    placeholder="size"
                    className="form-control"
                    style={{ width: "100px" }}
                  />
                </th>
                <th>
                  <input
                    name="quantity"
                    value={x.quantity}
                    onChange={(e) => onInputChange(e, i)}
                    placeholder="quantity"
                    className="form-control quantity"
                    style={{ width: "200px" }}
                  />
                </th>
                <th className="btn-box">
                  {inputList.length !== 1 && (
                    <Button
                      variant="contained"
                      color="error"
                      type="button"
                      onClick={() => onRemove(i)}
                    >
                      Remove
                    </Button>
                  )}
                </th>
                <th>
                  {inputList.length - 1 === i && (
                    <Button
                      variant="contained"
                      color="success"
                      type="button"
                      onClick={onAdd}
                    >
                      Add
                    </Button>
                  )}
                </th>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};