import React from 'react';

export const CheckMark = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{ enableBackground: "new 0 0 24 24" }}
  >
    <path
      fill="#FFFFFF"
      d="M20.3,2L9,13.6l-5.3-5L0,12.3L9,21L24,5.7L20.3,2z"
    />
  </svg>
);

export const DarkCheckMark = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{ enableBackground: "new 0 0 24 24" }}
  >
    <path
      fill="#000000"
      d="M20.3,2L9,13.6l-5.3-5L0,12.3L9,21L24,5.7L20.3,2z"
    />
  </svg>
);